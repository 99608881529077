var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.labelTitle,
            "modal-class": [_vm.componentId],
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  !_vm.readOnly
                    ? _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.ok },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      )
                    : _vm._e(),
                  !_vm.readOnly
                    ? _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "danger" },
                          on: {
                            click: function ($event) {
                              return cancel()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                      )
                    : _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "secondary" },
                          on: {
                            click: function ($event) {
                              return cancel()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.close")))]
                      ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              on: { dismissed: _vm.dismissAlert },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container pl-0" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("task_predecessor.field.name"),
                            "label-for": "predecessor-name",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("Treeselect", {
                                attrs: {
                                  "instance-id": "predecessor-name",
                                  "default-expand-level": Infinity,
                                  options: _vm.optionTask,
                                  "data-vv-as": _vm.$t(
                                    "task_predecessor.field.name"
                                  ),
                                  "data-vv-name": "editPredecessor.uuId",
                                  "data-vv-delay": "500",
                                  disabled: _vm.readOnly,
                                },
                                model: {
                                  value: _vm.editPredecessor.uuId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editPredecessor, "uuId", $$v)
                                  },
                                  expression: "editPredecessor.uuId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-invalid-feedback",
                            {
                              staticClass: "alert-danger form-field-alert",
                              class: { "d-block": _vm.showNameError },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "circle-exclamation"] },
                              }),
                              _vm._v(
                                "  " +
                                  _vm._s(
                                    _vm.errors.first("editPredecessor.name")
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.customFieldMap["name"] != null
                    ? _vm._l(
                        _vm.customFieldMap["name"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "name" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id: `${_vm.componentId}_${field.name}`,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target: `${_vm.componentId}_${field.name}`,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled: _vm.readOnly,
                                    },
                                    model: {
                                      value: _vm.editPredecessor[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editPredecessor,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "editPredecessor[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.customFieldMap["default"] != null
                    ? _vm._l(
                        _vm.customFieldMap["default"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id: `${_vm.componentId}_${field.name}`,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target: `${_vm.componentId}_${field.name}`,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled: _vm.readOnly,
                                    },
                                    model: {
                                      value: _vm.editPredecessor[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editPredecessor,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "editPredecessor[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("task_predecessor.field.type"),
                            "label-for": "predecessor-type",
                          },
                        },
                        [
                          _c("multiselect", {
                            staticClass:
                              "custom-dropdown-options enable-option-icon",
                            attrs: {
                              "max-height": 300,
                              options: _vm.optionTaskLinkType.map((i) => i.key),
                              "custom-label": _vm.getPredecessorTypeOptionLabel,
                              placeholder: "",
                              searchable: false,
                              "allow-empty": false,
                              showLabels: false,
                              disabled: _vm.readOnly,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "option",
                                fn: function (props) {
                                  return [
                                    _vm.editPredecessor.type == props.option
                                      ? _c("font-awesome-icon", {
                                          staticClass: "selected-option-icon",
                                          attrs: { icon: ["far", "check"] },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      { staticClass: "option__title" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getPredecessorTypeOptionLabel(
                                              props.option
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.editPredecessor.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.editPredecessor, "type", $$v)
                              },
                              expression: "editPredecessor.type",
                            },
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            {
                              staticClass: "alert-danger form-field-alert",
                              class: { "d-block": _vm.showTypeError },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "circle-exclamation"] },
                              }),
                              _vm._v(
                                "  " +
                                  _vm._s(
                                    _vm.errors.first("editPredecessor.type")
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.customFieldMap["type"] != null
                    ? _vm._l(
                        _vm.customFieldMap["type"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "type" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id: `${_vm.componentId}_${field.name}`,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target: `${_vm.componentId}_${field.name}`,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled: _vm.readOnly,
                                    },
                                    model: {
                                      value: _vm.editPredecessor[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editPredecessor,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "editPredecessor[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "mr-2",
                                  attrs: { for: "predecessor-tag" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("task_predecessor.field.lag"))
                                  ),
                                ]
                              ),
                              _c("font-awesome-icon", {
                                staticClass: "mb-2",
                                style: {
                                  color: "var(--form-control-placeholder)",
                                  fontSize: "0.9em",
                                },
                                attrs: {
                                  id: "predecessor-lag",
                                  icon: ["far", "circle-question"],
                                },
                              }),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: "predecessor-lag",
                                    triggers: "hover",
                                    placement: "top",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "task_predecessor.field.lag_hint"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-input-group",
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { id: "LAG_SUBTRACT" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.lagAddMinus(-1)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "minus"] },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target: "LAG_SUBTRACT",
                                          placement: "top",
                                          triggers: "hover",
                                          content: _vm.$t(
                                            "task.button.lag_subtract"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  id: "predecessor-tag",
                                  type: "text",
                                  "data-vv-as": _vm.$t(
                                    "task_predecessor.field.lag"
                                  ),
                                  "data-vv-name": "editPredecessor.lag",
                                  "data-vv-delay": "500",
                                  readonly: _vm.readOnly,
                                },
                                on: { blur: _vm.lagFormat },
                                model: {
                                  value: _vm.editPredecessor.lag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editPredecessor, "lag", $$v)
                                  },
                                  expression: "editPredecessor.lag",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { id: "LAG_ADD" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.lagAddMinus(1)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target: "LAG_ADD",
                                          placement: "top",
                                          triggers: "hover",
                                          content: _vm.$t(
                                            "task.button.lag_add"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-form-invalid-feedback",
                            {
                              staticClass: "alert-danger form-field-alert",
                              class: { "d-block": _vm.showLagError },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "circle-exclamation"] },
                              }),
                              _vm._v(
                                "  " +
                                  _vm._s(
                                    _vm.errors.first("editPredecessor.lag")
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.customFieldMap["lag"] != null
                    ? _vm._l(
                        _vm.customFieldMap["lag"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "lag" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id: `${_vm.componentId}_${field.name}`,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target: `${_vm.componentId}_${field.name}`,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled: _vm.readOnly,
                                    },
                                    model: {
                                      value: _vm.editPredecessor[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editPredecessor,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "editPredecessor[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }